<template>
  <p class="date-published-before">
    {{ publishedBefore }}
  </p>
</template>

<script>
import FrontendDateParser from '@/utils/mixins/frontend-date-parser.js'

export default {
  mixins: [FrontendDateParser],
  props: {
    frontendDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    publishedBefore() {
      const dateNow = new Date()
      const datePublished = this.parseFrontendDate(this.frontendDate)

      const differenceMinutes = Math.abs(dateNow.getTime() - datePublished.getTime()) / (1000 * 60)
      if (differenceMinutes < 60) {
        return differenceMinutes >= 2 ? `vor ${Math.floor(differenceMinutes)} Minuten` : 'vor 1 Minute'
      }

      const differenceHours = differenceMinutes / 60
      if (differenceHours < 24) {
        return differenceHours >= 2 ? `vor ${Math.floor(differenceHours)} Stunden` : 'vor 1 Stunde'
      }

      return datePublished.toLocaleString('de', {
        day: 'numeric',
        month: 'long',
      })
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.date-published-before {
  color: $color-text-light;
  font-size: 14px;
  line-height: 14px;
  margin: 5px 0 0 0;
}
</style>
