<template>
  <NuxtLink :to="authorLink" rel="author" class="author-article">{{ authorName }}</NuxtLink>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorName() {
      const { prefixTitle, firstName, lastName, suffixTitle } = this.author.attributes

      return [prefixTitle, firstName, lastName, suffixTitle]
        .reduce((result, value) => {
          return value ? `${result} ${value}` : result
        }, '')
        .trim()
    },
    authorLink() {
      const authorSlug = this.$slugify([this.author.attributes.firstName, this.author.attributes.lastName].join(' '))
      return { name: 'author-id', params: { id: this.author.id, authorName: authorSlug } }
    },
  },
}
</script>

<style lang="scss">
.author-article {
  font-size: 18px;
}
</style>
