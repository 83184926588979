<template>
  <p class="date-article-teaser">
    {{ articleDate }}
  </p>
</template>

<script>
import FrontendDateParser from '@/utils/mixins/frontend-date-parser.js'

export default {
  mixins: [FrontendDateParser],
  props: {
    frontendDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    articleDate() {
      return this.parseFrontendDate(this.frontendDate).toLocaleString('de', {
        day: 'numeric',
        month: 'long',
      })
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.date-article-teaser {
  color: rgba($color-white, 0.8);
  font-size: 16px;
  margin: 0;
}
</style>
