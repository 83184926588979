<template>
  <div class="newsticker">
    <BoxHeader divider-mobile-only> Newsticker </BoxHeader>
    <div class="newsticker__content">
      <div class="newsticker__teasers">
        <TeaserNewstickerArticle
          v-for="article in newsArticles"
          :key="article.id"
          :article="article"
          class="newsticker__teaser-item"
        />
      </div>
      <ButtonLink v-if="isMoreNewsVisible" :link="moreNewsLink" class="newsticker__button-more"> Mehr News </ButtonLink>
    </div>
  </div>
</template>

<script>
import BoxHeader from '@/components/UserInterface/Box/BoxHeader'
import TeaserNewstickerArticle from '@/components/UserInterface/Teaser/TeaserNewstickerArticle'
import ButtonLink from '@/components/UserInterface/Button/ButtonLink'

export default {
  components: {
    BoxHeader,
    TeaserNewstickerArticle,
    ButtonLink,
  },
  props: {
    newsLimit: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    newsArticles() {
      return this.$store.getters['newsArticles/newsArticles'].slice(0, this.newsLimit)
    },
    newsChannel() {
      return this.$store.getters['channels/channelById'](this.$config.channelIdNews)
    },
    moreNewsLink() {
      return { path: `/${this.newsChannel.attributes.slug}` }
    },
    isMoreNewsVisible() {
      return this.$route.path !== this.moreNewsLink.path
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.newsticker {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
  }

  &__teasers {
    > :last-child {
      margin: 0;
    }
  }

  &__teaser-item {
    margin-bottom: 15px;

    @media (min-width: $screen-width-1023) {
      margin-bottom: 20px;
    }
  }

  &__button-more {
    margin-top: 20px;
    font-size: 18px;

    @media (min-width: $screen-width-1023) {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
