<template>
  <article class="article-teaser-newsticker">
    <div class="article-teaser-newsticker__image">
      <SpunqImage
        v-if="article.attributes.teaser && article.attributes.teaser.image"
        :image="article.attributes.teaser.image"
        :width="52"
        :height="52"
        :mobile-width="62"
        :mobile-height="62"
        mode="crop"
      />
    </div>
    <div>
      <h3 v-clampy="2" class="article-teaser-newsticker__title">
        <ArticleLink :article="article">
          {{ article.attributes.pageTitle }}
        </ArticleLink>
      </h3>
      <DatePublishedBefore :frontend-date="article.attributes.frontendDate" />
    </div>
  </article>
</template>

<script>
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'
import DatePublishedBefore from '@/components/UserInterface/Date/DatePublishedBefore'

export default {
  components: {
    SpunqImage,
    ArticleLink,
    DatePublishedBefore,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.article-teaser-newsticker {
  @include clickable-article-teaser;
  @include hovering-translate-right;

  display: flex;

  &__image {
    width: 62px;
    height: 62px;
    flex-shrink: 0;
    margin-right: 15px;

    @media (min-width: $screen-width-1023) {
      width: 52px;
      height: 52px;
      margin-right: 20px;
    }
  }

  &__title {
    @include bold-source-sans-pro;

    font-size: 18px;
    line-height: 20px;
    margin: 0;

    @media (min-width: $screen-width-1023) {
      font-size: 16px;
      line-height: 16px;
    }

    a {
      color: $color-headline;
    }
  }
}
</style>
