/**
 * Allows components to parse 'frontendDate' in a safe and standard way.
 * Note: Raw 'frontendDate' attribute comes in '2019-08-27T09:16:22+0200'
 * format, which cannot be parsed directly by Safari and IE11 browsers.
 */
export default {
  methods: {
    parseFrontendDate(frontendDate) {
      return new Date(frontendDate.replace(/-/g, '/').replace('T', ' ').replace('+', ' GMT+'))
    },
  },
}
