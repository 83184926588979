<template>
  <div class="event-calendar">
    <div class="event-calendar__year">
      {{ date.year }}
    </div>
    <div class="event-calendar__weekday">
      {{ date.weekday }}
    </div>
    <div class="event-calendar__day">
      {{ date.day }}
    </div>
    <div class="event-calendar__month">
      {{ date.month }}
    </div>
  </div>
</template>

<script>
import FrontendDateParser from '@/utils/mixins/frontend-date-parser'

export default {
  mixins: [FrontendDateParser],
  props: {
    frontendDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    date() {
      const date = this.parseFrontendDate(this.frontendDate)
      const year = date.getFullYear()
      const weekday = date.toLocaleString('de', {
        weekday: 'short',
      })
      const day = date.getDate()
      const month = date.toLocaleString('de', {
        month: 'short',
      })
      return {
        year,
        weekday,
        day,
        month,
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/import/vars';
@import '../../../../assets/scss/import/mixins';

$color-gray-month: #999;
$color-box-shadow-calendar: rgba(0, 0, 0, 0.2);

.event-calendar {
  @include source-sans-pro-font-weight(600);

  height: 88px;
  width: 80px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $color-box-shadow-calendar;
  background: $color-white;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;

  &__year {
    width: 80px;
    color: $color-white;
    padding: 2px 0;
    background: $color-brown;
  }

  &__weekday {
    color: $color-gray-dark;
    text-transform: uppercase;
    margin-top: 7px;
  }

  &__day {
    @include bold-source-sans-pro;

    color: $color-brown;
    font-size: 24px;
    line-height: 24px;
  }

  &__month {
    color: $color-gray-month;
    font-size: 12px;
    margin-top: 2px;
    text-transform: uppercase;
  }
}
</style>
