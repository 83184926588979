<template>
  <article class="article-teaser">
    <SpunqImage
      v-if="article.attributes.teaser && article.attributes.teaser.image"
      :image="article.attributes.teaser.image"
      :width="585"
      :height="isWide ? 560 : 1040"
      :mobile-width="740"
      :mobile-height="700"
      mode="crop"
    />
    <div class="article-teaser__content">
      <ChannelTag class="article-teaser__tag">
        {{ article.relationships.channels[0].attributes.name }}
      </ChannelTag>
      <div class="article-teaser__teaser-header">
        <h3 v-clampy="3" class="article-teaser__title" :class="{ 'article-teaser__title--big': isWithoutDate }">
          <ArticleLink :article="article">
            {{ article.attributes.pageTitle }}
          </ArticleLink>
        </h3>
        <DateArticleTeaser v-if="!isWithoutDate" :frontend-date="article.attributes.frontendDate" />
      </div>
    </div>
    <div class="article-teaser__shadow-gradient" />
  </article>
</template>

<script>
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'
import ChannelTag from '@/components/UserInterface/Tag/ChannelTag'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'
import DateArticleTeaser from '@/components/UserInterface/Date/DateArticleTeaser'

export default {
  components: {
    SpunqImage,
    ChannelTag,
    ArticleLink,
    DateArticleTeaser,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
    isWithoutDate: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.article-teaser {
  @include clickable-article-teaser;
  @include hovering-scale-image;
  @include hovering-overlay-image;

  background: $color-black-light;
  height: 240px;
  overflow: hidden;

  @media (min-width: $screen-width-1023) {
    height: 400px;
  }

  .responsive-image {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 15px 8px 15px;
    z-index: 2;

    @media (min-width: $screen-width-1023) {
      padding: 20px 20px 12px 20px;
    }
  }

  &__shadow-gradient {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(transparent, $color-gray-dark);
  }

  .link-article {
    color: $color-white;
  }

  &__teaser-header {
    width: 100%;
  }

  &__title {
    @include bold-source-sans-pro;

    font-size: 26px;
    margin-bottom: 2px;
    line-height: 30px;

    @media (min-width: $screen-width-1023) {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 5px;
    }

    &--big {
      padding-bottom: 10px;

      @media (min-width: $screen-width-1023) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}
</style>
