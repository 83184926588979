<template>
  <header class="header-box" :class="headerModifierClasses">
    <BoxHeadline :small="small">
      <slot />
    </BoxHeadline>
    <Divider v-if="!small" :class="dividerModifierClasses" />
  </header>
</template>

<script>
import BoxHeadline from '@/components/UserInterface/Box/BoxHeadline'
import Divider from '@/components/UserInterface/Icon/divider.svg'

export default {
  components: {
    BoxHeadline,
    Divider,
  },
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    dividerMobileOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headerModifierClasses() {
      const modifiers = []

      if (this.centered) {
        modifiers.push('header-box--centered')
      }

      if (this.small) {
        modifiers.push('header-box--small')
      }

      return modifiers.reduce((result, item) => `${result} ${item}`, '').trim()
    },
    dividerModifierClasses() {
      return this.dividerMobileOnly ? 'header-box__divider--mobile-only' : null
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 26px;

  @media (min-width: $screen-width-1023) {
    margin-bottom: 38px;
  }

  &--centered {
    @media (min-width: $screen-width-1023) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      :last-child {
        margin-top: 30px;
      }
    }
  }

  &--small {
    margin-bottom: 17px;

    @media (min-width: $screen-width-1023) {
      margin-bottom: 28px;
    }
  }

  &__divider {
    &--mobile-only {
      @media (min-width: $screen-width-767) {
        display: none;
      }
    }
  }
}
</style>
