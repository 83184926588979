<template>
  <h2 class="box-headline" :class="headlineModifierClasses">
    <slot />
  </h2>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    headlineModifierClasses() {
      return this.small ? 'box-headline--small' : null
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.box-headline {
  @include bold-source-sans-pro;

  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 1.83px;
  line-height: 18px;
  margin: 0;

  @media (min-width: $screen-width-1023) {
    font-size: 24px;
    letter-spacing: 2px;
  }

  &--small {
    font-size: 18px;
    letter-spacing: 1.8px;
  }
}
</style>
