<template>
  <div class="article-default-meta">
    <DateArticleBig :frontend-date="article.attributes.frontendDate" />
    <template v-if="hasAuthor">
      <span class="article-default-meta__separator">-</span>
      <template v-for="(author, index) in article.relationships.authors">
        <AuthorArticle :key="author.id" :author="author" /><template
          v-if="index < article.relationships.authors.length - 1"
          >,&nbsp;</template
        >
      </template>
    </template>
  </div>
</template>

<script>
import DateArticleBig from '@/components/UserInterface/Date/DateArticleBig'
import AuthorArticle from '@/components/UserInterface/Author/AuthorArticle'

export default {
  components: {
    DateArticleBig,
    AuthorArticle,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasAuthor() {
      return this.article.relationships.authors.length > 0
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.article-default-meta {
  max-width: 600px;

  &__separator {
    margin: 0 3px;
    color: $color-text-very-light;
  }
}
</style>
