<template>
  <div class="box" :class="boxModifierClasses">
    <div class="box__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Determines the upper and the lower padding of the box.
     * `small, standard, large`
     */
    size: {
      type: String,
      required: false,
      default: 'standard',
    },
    grayBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    boxModifierClasses() {
      const sizeModifier = `box--${this.size}`
      return this.grayBackground ? `${sizeModifier} box--background-gray` : sizeModifier
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.box {
  position: relative;

  &--small {
    padding: 20px 0;

    @media (min-width: $screen-width-1023) {
      padding: 30px 0;
    }
  }

  &--standard {
    padding: 30px 0;

    @media (min-width: $screen-width-1023) {
      padding: 60px 0;
    }
  }

  &--large {
    padding: 30px 0;

    @media (min-width: $screen-width-1023) {
      padding: 78px 0 110px 0;
    }
  }

  &--background-gray {
    background-color: $color-white-dark;
  }

  &__content {
    width: 100%;
    max-width: $max-width-content;
    margin: auto;
    padding: 0 15px;

    @media (min-width: $screen-width-1300 + 50px) {
      padding: 0;
    }
  }
}
</style>
