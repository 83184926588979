<template>
  <div class="event">
    <div class="event__image-container">
      <ImageCaption
        v-if="event.attributes.teaser && event.attributes.teaser.image"
        :image="event.attributes.teaser.image"
        :width="620"
        :height="360"
        :mobile-width="759"
        :mobile-height="308"
        mode="crop"
        class="event__image"
      />
      <template v-else>
        <img
          src="/img/events-placeholder-desktop.png"
          alt="Placeholder"
          class="event__image event__image-placeholder--desktop"
        />
        <img
          src="/img/events-placeholder-mobile.png"
          alt="Placeholder"
          class="event__image event__image-placeholder--mobile"
        />
      </template>
    </div>
    <EventCalendar :frontend-date="event.attributes.startDate" class="event__calendar" />
    <div class="event__content-container">
      <ArticleLink :article="event">
        <h2 v-clampy="2" class="event__title">
          {{ event.attributes.pageTitle }}
        </h2>
      </ArticleLink>
      <p v-clampy="3" class="event__content">
        {{ event.attributes.leadText }}
      </p>
      <div class="event__footer">
        <div v-if="event.attributes.location" class="event__location">
          <IconMarker />
          <span class="event__location-content">
            {{ event.attributes.location }}
          </span>
        </div>
        <div v-if="isUpcomingEvent" class="event__button-container">
          <a :href="eventEmailLink" target="_blank" class="button-link event__button">Anmelden</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventCalendar from '@/components/UserInterface/Teaser/EventTeaser/EventTeaserCalendar'
import ImageCaption from '@/components/UserInterface/Image/ImageCaption'
import IconMarker from '@/components/UserInterface/Icon/icon-marker.svg'
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'

export default {
  components: { ArticleLink, ImageCaption, EventCalendar, IconMarker },
  props: {
    event: {
      required: true,
      type: Object,
    },
    isUpcomingEvent: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    eventEmailLink() {
      return `mailto:${this.$config.eventEmail}?subject=${this.event.attributes.pageTitle}`
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/import/vars';
@import '../../../../assets/scss/import/mixins';

.event {
  @include button-link;
  @include clickable-article-teaser;
  @include hovering-scale-image(1.01);

  border: 1px solid $color-gray-alto;
  border-radius: 2px;
  background-color: $color-white;
  position: relative;
  margin: 0 auto 15px;
  height: 370px;
  width: 100%;

  @media (min-width: $screen-width-767) {
    max-width: 310px;
    height: 440px;
    margin: 0 auto 15px;
  }

  &__calendar {
    position: absolute;
    top: 15px;
    left: 15px;

    @media (min-width: $screen-width-767) {
      top: 20px;
      left: 20px;
    }
  }

  &__image-container {
    height: 140px;
    overflow: hidden;
    position: relative;

    @media (min-width: $screen-width-767) {
      height: auto;
    }
  }

  & &__image {
    position: absolute;
    width: 100%;
    margin: 0;

    @media (min-width: $screen-width-767) {
      position: relative;
    }

    &-placeholder {
      &--desktop {
        display: none;
      }

      @media (min-width: $screen-width-767) {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: block;
        }
      }
    }
  }

  &__content-container {
    padding: 15px;
    font-family: $source-sans-pro;
    letter-spacing: 0;

    @media (min-width: $screen-width-767) {
      padding: 15px 20px;
    }
  }

  &__content {
    color: $color-gray-dark;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }

  &__title {
    @include bold-source-sans-pro;

    color: $color-brown-dark;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 9px;
    letter-spacing: 0;
  }

  &__location {
    display: flex;
    margin-bottom: 15px;
  }

  &__location-content {
    color: $color-gray-boulder;
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }

  &__footer {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
  }

  & &__button {
    display: flex;
    max-height: 44px;
    width: 100%;
    font-size: 18px;
    justify-content: center;

    @media (min-width: $screen-width-767) {
      max-height: 32px;
      font-size: 16px;
      padding: 0 20px;
      line-height: 32px;
      width: auto;
    }
  }

  &__button-container {
    @media (min-width: $screen-width-767) {
      display: flex;
      justify-content: center;
      border-top: 1px solid $color-gray-alto;
      padding-top: 15px;
    }
  }
}
</style>
