<template>
  <span class="date-article-big">
    {{ articleDate }}
  </span>
</template>

<script>
import FrontendDateParser from '@/utils/mixins/frontend-date-parser.js'

export default {
  mixins: [FrontendDateParser],
  props: {
    frontendDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    articleDate() {
      return this.parseFrontendDate(this.frontendDate).toLocaleString('de', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.date-article-big {
  font-size: 18px;
  color: $color-text-very-light;
}
</style>
